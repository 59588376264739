import { render, staticRenderFns } from "./CreditCardDeniedAttempts.vue?vue&type=template&id=40bb90d6&scoped=true&"
import script from "./CreditCardDeniedAttempts.vue?vue&type=script&lang=js&"
export * from "./CreditCardDeniedAttempts.vue?vue&type=script&lang=js&"
import style0 from "./CreditCardDeniedAttempts.vue?vue&type=style&index=0&id=40bb90d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40bb90d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
