<template>
  <div class="page page--center">
    <div class="page__container">
      <img class="page__container__logo" src="@/assets/svg/logo-99.svg" alt="Logo 99 Empresas">

      <div class="text--center mt-9">
        <h1 v-if="creditCardAttempts < 3" class="page__container__title">
          <span class="color--error">Cartão negado pelo adquirente :(</span><br>
          Quer tentar novamente?
        </h1>

        <h1 v-else class="page__container__title">
          <span class="color--error">Cartão negado pelo adquirente :(</span><br>
          E você já esgotou o limite para tentativas com este cartão. <span>Deseja tentar com um novo cartão?</span>
        </h1>

        <div class="pt-4 pb-5">
          <img src="@/components/paymentMethod/tips/icons/card-check.svg" alt="Ícone cartão de crédito" class="icon">
        </div>

        <div class="mb-3">
          <v-btn
            :to="{ name: 'PaymentMethod' }"
            :title="buttonTitle"
            color="warning--gradient"
            depressed
            rounded
            large
          >
            {{ buttonTitle }}
          </v-btn>
        </div>

        <a
          href="https://99app.com/empresas"
          title="Voltar para a página do 99Empresas"
          class="text--small"
        >
          Voltar para a página da 99Empresas
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CreditCardDeniedAttempts',
  metaInfo () {
    return {
      titleTemplate: this.routeTitle + ' | %s'
    }
  },
  computed: {
    ...mapState({
      creditCardAttempts: ({ prospect }) => prospect.creditCardAttempts
    }),

    routeTitle () {
      return this.$route.meta.title
    },

    buttonTitle () {
      return this.creditCardAttempts < 3 ? 'Tentar novamente' : 'Tentar com outro cartão'
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {

  @media (min-width: 1200px) {
    height: 48px;
    width: 48px;
  }
}
</style>
